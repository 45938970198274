
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";

export default defineComponent({
  name: "CreateAccount",
  created() {
    if (localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }
    /*
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const userData = localStorage.getItem("userData");
          if (!userData) {
            auth.signOut();
            this.$store.commit("auth/setUserLogin", null, { root: true });
          }
          this.$router.replace("/");
        } else {
          localStorage.removeItem("userData");
        }
      });
      //*/
    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      // ElMessage.error(value);
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    loadingStatus(value) {
      const loading = ElLoading.service({
        lock: true,
        text: "Login..",
        background: "#ffffff90",
      });
      if (!value) {
        loading.close();
      }
    },
  },
  computed: {
    userLogin() {
      return this.$store.getters.getLoginStatus;
    },
    loadingStatus() {
      return this.$store.getters.getLoadingStatus;
    },
    error() {
      return this.$store.getters["auth/error"];
    },
    form() {
      return this.$store.getters["CreateAccountForm/formValues"];
    },
    createSteb() {
      return this.$store.getters["CreateAccountForm/createStep"];
    },
  },
  data() {
    return {
      page: "login",
      loadingImageFile: false,
      disableButton: false,
      // form: {
      //   email: "",
      //   firstName: "",
      //   lastName: "",
      //   companyName: "",
      //   jopTitle: "",
      //   hiresPerYears: "",
      //   cultureDevelopment: "",
      //   color: "#ffffff",
      //   imageFile: "",
      //   acceptTerrms: true,
      //   sendFreeStuff: true,
      //   companyUploadedFile: new File([], ""),
      //   companyUploadedFileDataUrl: "",
      //   companyLogoUrl: "",
      // },
      formValidate: {
        email: false,
        firstName: false,
        lastName: false,
        companyName: false,
        jopTitle: false,
        hiresPerYears: false,
        // cultureDevelopment: false,
        color: false,
        imageFile: false,
        acceptTerrms: true,
        sendFreeStuff: true,
      },
    };
  },
  mounted() {
    if (this.userLogin) {
      this.$router.push("/");
    }
    this.setLangOnInit();
  },
  methods: {
    setLangOnInit() {
      const lang = this.$router.currentRoute.value.meta.lang || "en";
      if (lang && lang == "TH") {
        (this as any).$i18n.setLocale("th");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      } else {
        (this as any).$i18n.setLocale("en");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      }
    },
    handleClickAuthProvider(provider: string) {
      this.signinByProvider(provider);
    },
    signinByProvider(provider: string) {
      const authData = {
        email: null,
        provider: provider,
      };
      this.$store.dispatch("auth/login", authData, { root: true });
    },
    loginWithGoogle() {
      this.$store.dispatch("userLoginWidthGoogle", true);
    },
    validateStyle(status: boolean) {
      if (status) {
        return "validate-true";
      }
      return "validate-false";
    },
    fontColor(status: boolean) {
      if (status) {
        return "color: red;";
      }
      return "color: #000;";
    },
    async login() {
      // @see ConfirmCandidate.vaue
      // Why you remove previous logic
      this.formValidate.email = true;
      const email = this.form.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (email) {
        this.disableButton = true;
        this.formValidate.email = false;
        const valid = await this.$store.dispatch(
          "auth/requestLoginWeb",
          { email: this.form.email },
          { root: true }
        );
        if (valid) {
          this.$router.push("/pincode");
        } else {
          this.formValidate.email = true;
          this.disableButton = false;
        }
      }
    },
  },
});
